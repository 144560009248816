// UI Block functions library
// Depends on jQuery
(function(window){
    'use strict';

    function uiBlock(){
        var _uiBlock = {};

        // Define constants - TODO Allow override at init
        _uiBlock.BREAKPOINTS = {
            SM: 576,
            MD: 768,
            LG: 992,
            XL: 1140
        };

        // Function to force all block height to the higher one.
        _uiBlock.setBlockHeight = function(parentSelector) {
            //console.log('Call _uiBlock.setBlockHeight - ' + parentSelector);

            //Inner function
            function setBlockHeight(selector) {

                var elements = document.querySelectorAll(selector);
                //console.log(elements);

                if(elements) {
                    setHeightAuto(elements);

                    var maxHeight = 0;
                    elements.forEach(function(element) {
                        if (element.offsetHeight > maxHeight) {
                            maxHeight = element.offsetHeight;
                        }
                    });

                    // jQuery(selector).outerHeight(maxHeight);
                    elements.forEach(function(element) {
                        element.style.height = maxHeight + 'px';
                    });

                } else {
                    return;
                }
            }

            // Xtra small devices
            //console.log('xs ' + window.innerWidth);
            setBlockHeight(parentSelector + ' .block-height-xs');

            var elementsAll = document.querySelectorAll(parentSelector + ' .block-height-sm,'+ parentSelector + ' .block-height-md,' + parentSelector +' .block-height-lg');
            if (window.innerWidth < _uiBlock.BREAKPOINTS.SM && elementsAll.length > 0) {
                setHeightAuto(elementsAll);
                return;
            }

            // Small devices
            //console.log('sm ' + window.innerWidth);
            setBlockHeight(parentSelector + ' .block-height-sm');

            var elementsMdLg = document.querySelectorAll(parentSelector + ' .block-height-md,'+ parentSelector + ' .block-height-lg');
            if (window.innerWidth < _uiBlock.BREAKPOINTS.MD && elementsMdLg.length > 0) {
                setHeightAuto(elementsMdLg);
                return;
            }

            // Medium devices
            //console.log('md ' + window.innerWidth);
            setBlockHeight(parentSelector + ' .block-height-md');

            var elementsLg = document.querySelectorAll(parentSelector + ' .block-height-lg');
            if (window.innerWidth < _uiBlock.BREAKPOINTS.LG && elementsLg.length > 0) {
                setHeightAuto(elementsLg);
                return;
            }

            // Large devices
            //console.log('lg ' + window.innerWidth);
            setBlockHeight(parentSelector + ' .block-height-lg');
        };

        // To set 'height:auto' for an array of elements
        function setHeightAuto(elements) {
            elements.forEach(function(element) {
                element.style.height = "auto";
            });
        }

        return _uiBlock;
    }

    // Expose the library
    if(typeof(window.pomloUIBlock) === 'undefined'){
      window.pomloUIBlock = uiBlock();
    }
  })(window);
