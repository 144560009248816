var pomlo_app = {};

// Define constants
pomlo_app.BREAKPOINTS = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1140
};
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.HEADER_HEIGHT = 0;


/**
 * Function to scroll to a specific html element
 */
pomlo_app.scrollTo = function(selector) {
    jQuery(selector).animatescroll( {
        padding: pomlo_app.HEADER_HEIGHT,
        scrollSpeed: 600,
        easing: 'easeInOutQuad'
    });
};

// Observer and animation functions
pomlo_app.observeSection = function(selector, func, options) {
    var section = document.querySelector(selector);

    var sectionObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.intersectionRatio > 0) {
                func(options);
                observer.unobserve(entry.target);
            }
        });
    });

    if (section) {
        sectionObserver.observe(section);
    }
};

pomlo_app.animateFadeIn = function(selector) {
    jQuery(selector).addClass('animate__animated animate__fadeIn animate__slow animate__delay-1s');
};

pomlo_app.animateFadeInRight = function(selector) {
    jQuery(selector).addClass('animate__animated animate__fadeInRight animate__slow');
};

pomlo_app.animateFadeInLeft = function(selector) {
    jQuery(selector).addClass('animate__animated animate__fadeInLeft animate__slow');
};

pomlo_app.animateDomains = function() {
    jQuery('.domain').each(function() {
        jQuery(this).addClass('animate__animated animate__fadeIn animate__slow animate__delay-1s');
    });
};
// /Observer and animation functions

jQuery(document).ready(function($) {
    // Loader
    jQuery('.img-loader-wait').imagesLoaded(function() {
        var delay = document.location.host ? 0 : 500;
        setTimeout(function(){
            jQuery("#loader").fadeOut();
        }, delay);
    });

    // Init interface
    $('#testimonials').owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        nav: false,
        dots: false,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
    });

    // Removes some anchor links managed by js click/animatescroll.
    // And manage scroll buttons
    $(".scroll-link").removeAttr("href");

    $(".scroll-to-top").click(function() {
        pomlo_app.scrollTo("#page-top");
    });

    $(".scroll-to").click(function(){
        pomlo_app.scrollTo($(this).attr("data-target"));
    });

    // Setup intersection observers to trigger actions
    jQuery('.animated-fadein').each(function() {
        var selector = '#' + jQuery(this).attr('id');
        pomlo_app.observeSection(selector, pomlo_app.animateFadeIn, selector);
    });

    jQuery('.animated-fadein-left').each(function() {
        var selector = '#' + jQuery(this).attr('id');
        pomlo_app.observeSection(selector, pomlo_app.animateFadeInLeft, selector);
    });

    jQuery('.animated-fadein-right').each(function() {
        var selector = '#' + jQuery(this).attr('id');
        pomlo_app.observeSection(selector, pomlo_app.animateFadeInRight, selector);
    });

    pomlo_app.observeSection('#page-domains', pomlo_app.animateDomains);
});

jQuery(window).scroll(function() {

});

jQuery(window).resize(function() {

});
